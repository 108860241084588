<template>
  <el-card class="box-card">
    <el-form ref="form" label-width="150px" :model="form">
      <el-form-item style="margin-bottom: 20px" label="免费包邮" prop="">
        <el-switch
            v-model="form"
            active-color="#13ce66"
            active-text="是"
            active-value="1"
            inactive-color="#ff4949"
            inactive-text="否"
            inactive-value="0"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="每月赠送优惠券" prop="">
        <el-button size="small" type="primary">添加优惠券（无限制）</el-button>
        <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px" height="300">
          <el-table-column
              align="center"
              prop="name"
              label="优惠券名">
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              prop="state"
              label="现有余量">
          </el-table-column>
          <el-table-column align="center" label="操作" width="60">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="" prop="">
        <span>消费积分在原积分转换比例上增加</span>
        <el-input type="number" v-model="form" style="width: 10%; margin: 0 5px" size="small"/>
        <span>%（举例说明，假设系统设置是1实付金额等于2积分，Plus会员增加比例是130%，那Plus会员将产生2.6积分）</span>
      </el-form-item>
      <el-divider content-position="left">首次成功付费会员额外赠送</el-divider>
      <el-form-item style="margin-bottom: 20px" label="首次赠送-优惠券" prop="">
        <el-button size="small" type="primary">添加优惠券（无限制）</el-button>
        <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px" height="300">
          <el-table-column
              align="center"
              prop="name"
              label="优惠券名">
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              prop="state"
              label="现有余量">
          </el-table-column>
          <el-table-column align="center" label="操作" width="60">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="首次赠送-积分" prop="">
        <el-input type="number" v-model="form" style="width: 10%;" size="small"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="首次赠送-余额" prop="">
        <el-input type="number" v-model="form" style="width: 10%;" size="small"/>
      </el-form-item>
      <el-divider content-position="left">付费会员购物折扣</el-divider>
      <el-form-item style="margin-bottom: 20px" label="折扣比例" prop="">
        <el-input type="number" v-model="form" style="width: 10%;" size="small"/>
        <span> %</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      list_state: false,
      list: [{id: 1}, {id: 2}, {id: 3}],
      form: {
        country: '',
        ip: '',
      },
    }
  },
  created() {
    this.requestObtain()
  },
  methods: {
    async requestObtain() {
      const {data} = await getExam()
      this.form.num = data.num
      this.form.content = data.content
    },
    async submit() {
      const {code, message} = await Cac(this.form)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
  }
}

</script>
<style>
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
